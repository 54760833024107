import { Link } from "gatsby"
import React from "react"
import styles from "../../templates/blog-list.module.css"
import Img from "gatsby-image"

const LinkBox = ({link, linkText, name, img, description}) =>
  <article key={link} className={styles.article}>
    <header className={styles.titleHolder}>
      <h2 className={styles.title}>
        <Link className={styles.titleLink} to={link}>
          {name}
        </Link>
      </h2>
    </header>
    <div className={styles.imageHolder}>
      <Link className={styles.imageLink} to={link}>
        <Img fixed={img} />
      </Link>
    </div>
    <div className={styles.lead}>
      <section>
          {description}
        <Link className={styles.button} to={link}>
          {linkText}
        </Link>
      </section>
    </div>
  </article>

export default LinkBox
