import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkBox from "../components/tools/LinkBox"

const Recommendations = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const image = name => data.allImageSharp.nodes.find(node => node.fixed.originalName === name).fixed

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Lottolibrary Recommends" />
      <h1>Lottolibrary Recommends</h1>
      <LinkBox link="/books/" name="Recommended Books" img={image("book.jpg")} linkText="LEARN MORE"
             description="The best lottery-related books: lottery strategies and practical guides." />
    </Layout>
  )
}

export default Recommendations

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allImageSharp {
      nodes {
        fixed(width: 300, height: 225) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
          originalName
        }
      }
    }
  }
`
